@import "../../scss/typography.scss";

.typography {
  margin-block: 0px;
  margin-inline: 0px;

  &--regular {
    font-weight: 400;
  }
  &--medium {
    font-weight: 500;
  }
  &--semibold {
    font-weight: 600;
  }
  &--bold {
    font-weight: 700;
  }
}

.typography__display {
  &--2xl {
    font-size: map-get($displaySizes, 2xl);
  }
  &--xl {
    font-size: map-get($displaySizes, xl);
  }
  &--lg {
    font-size: map-get($displaySizes, lg);
  }
  &--md {
    font-size: map-get($displaySizes, md);
  }
  &--sm {
    font-size: map-get($displaySizes, sm);
  }
  &--xs {
    font-size: map-get($displaySizes, xs);
  }
}

.typography__text {
  &--xl {
    font-size: map-get($textSizes, xl);
  }
  &--lg {
    font-size: map-get($textSizes, lg);
  }
  &--md {
    font-size: map-get($textSizes, md);
  }
  &--sm {
    font-size: map-get($textSizes, sm);
  }
  &--xs {
    font-size: map-get($textSizes, xs);
  }
}
