@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.rangePickerWrapper {
    // display: flex;
    // flex-flow: column;
    min-width: 300px;
    padding: 15px 20px;
}

.rangeContainer {
    margin: 24px 0;
}

.renderTrack {
    height: 8px;
    width: 100%;
    display: flex;
    width: 100%;
    border-radius: 4px;
    // margin: 0 10px,
}

.renderThumb {
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 50%;
    border: 1.5px solid map-get($primary, 600);
    transition: box-shadow ease-in-out 100ms;
}   

.rangeLabel {
    color: map-get($gray, 700);
    text-align: left;
}

.textInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__input {
        width: 76px;
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid map-get($gray, 300);
        label {
            color: map-get($gray, 500);
            font-size: map-get($textSizes, xs);
        }
        input {
            border: none;
            width: 100%;
            font-size: map-get($textSizes, md);
            color: map-get($gray, 900);

            &:focus {
            outline: none;
            }
        }
    }
}

