@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

$border: 1px solid map-get($gray, 300);

.tableContainer {
  .tableDateRange {
    margin-bottom: 19px;
  }

  .midSection {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    align-items: center;

    @include breakpoint-to(lg) {
      flex-direction: column;
      align-items: baseline;
    }

    @include breakpoint-to(sm) {
      gap: 8px;
      justify-content: start;
    }

    &__left {
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  .dropdownFilter {
    width: fit-content;

    &__control {
      min-width: 100px;
      background-color: map-get($gray, 700);
      &:hover {
        background-color: map-get($gray, 600);
        cursor: pointer;
      }
    }

    &__value {
      color: $white;
      font-weight: 600;
    }

    &__indicator {
      color: $white;
    }

    &__menu {
      width: auto;
      z-index: 30;
    }
  }

  .quickSearchFilter {
    // min-width: 350px;
    max-width: 100%;

    &__icon {
      font-size: map-get($textSizes, xl);
      color: map-get($gray, 500);
      margin: 1.5px;
    }
  }

  .customizeFilters {
    position: relative;
    button {
      height: 42px;
      align-items: center;
    }

    &__menu {
      background-color: $white;
      position: absolute;
      top: 54px;
      z-index: 10;
      border: 1px solid map-get($gray, 200);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      width: min-content;

      &__range {
        min-width: 360px;
        padding: 20px;

        &__label {
          color: map-get($gray, 700);
        }

        &__slider {
          margin-top: 16px;
          margin-bottom: 24px;

          &__thumb {
            height: 24px;
            width: 24px;
            background-color: white;
            border-radius: 50%;
            border: 1.5px solid map-get($primary, 600);
            transition: box-shadow ease-in-out 100ms;

            &:focus,
            &:hover {
              outline: none;
              box-shadow: 0px 0px 0px 4px #f4ebff,
                0px 1px 2px 0px rgba(16, 24, 40, 0.06),
                0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
            }
          }
        }

        &__textInputs {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &__input {
            width: 76px;
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid map-get($gray, 300);

            label {
              color: map-get($gray, 500);
              font-size: map-get($textSizes, xs);
            }

            input {
              border: none;
              width: 100%;
              font-size: map-get($textSizes, md);
              color: map-get($gray, 900);

              &:focus {
                outline: none;
              }
            }
          }
        }
      }

      &__divider {
        height: 1px;
        background-color: map-get($gray, 200);
        margin: 0 20px 20px;
      }

      &__option {
        min-width: 204px;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        color: map-get($gray, 700);
        transition: background-color cubic-bezier(0.455, 0.03, 0.515, 0.955)
          500ms;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: map-get($primary, 100);
        }

        &__icon {
          font-size: map-get($textSizes, xl);
        }
      }
    }
  }

  .actionButtons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-left: auto;

    @include breakpoint-to(lg) {
      margin-left: 0;
    }

    button {
      display: flex;
      align-items: center;
    }

    svg {
      font-size: 20px;
    }

    .downloadIcon {
      color: #8bb9a8;
    }

    .mailIcon {
      color: #dcb972;
    }

    .printIcon {
      color: #57adca;
    }
  }

  .tableContent {
    overflow: auto;
    height: 100%;
    position: relative;
    // min-height: 400px;
    border: $border;
  }

  .table {
    width: 100%;
    color: map-get($gray, 600);
    table-layout: auto;
    position: relative;
    border-spacing: 0;
    min-height: 250px;

    &__header {
      &__item {
        background-color: map-get($gray, 50);
        // position: sticky;
        top: 0;
        height: 34px;

        font-weight: 700;
        font-size: map-get($textSizes, xs);
        border-top: $border;
        border-right: $border;
        border-bottom: $border;
        padding: 5px 10px;

        &:first-child {
          border-left: $border;
        }

        &--sortable {
          display: flex;
          cursor: pointer;
          user-select: none;
          align-items: center;
          justify-content: space-between;

          &__icon {
            flex-shrink: 0;
            font-size: map-get($textSizes, md);
          }
        }
      }
    }

    &__header__title {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: center;
      // overflow: hidden;
    }

    &__header__title__padding {
      padding: 5px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .body {
      vertical-align: baseline;
      &__row {
        &__item {
          border-bottom: $border;
          font-size: map-get($textSizes, sm);
          padding: 15px 16.5px;
          // white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:first-child {
            border-left: $border;
          }

          &:last-child {
            border-right: $border;
          }

          &--noData {
            height: 200px;
            text-align: center;
          }
        }
      }
    }
  }

  .tableNavigation {
    position: sticky;
    bottom: 0;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 8px;
    padding: 14px 24px 18px;
    border: $border;
    border-top: 0;

    button {
      width: unset;
    }

    &__paginationActions {
      display: flex;
      align-items: center;
      gap: 8px;

      &__rowsPerPageLabel {
        @include breakpoint-to(lg) {
          display: none;
        }
      }

      &__prevNext {
        align-items: center;
        display: flex;
        gap: 8px;

        &__icon {
          font-size: map-get($textSizes, xl);
        }
      }
    }

    &__pages {
      display: flex;

      button {
        font-weight: 500;
        border-radius: 8px;
      }

      &__page--isCurrentPage {
        font-weight: 600;
        color: map-get($gray, 800) !important;
        background-color: map-get($gray, 50) !important;
      }
    }
  }
}

.columnFilter {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  flex-flow: row wrap;
}

.columnfilter_input {
  flex: 1;
  border-radius: 5px;
}

.table-title-flex {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

