/* Popover.css */
.popover-container {
    position: relative;
    display: inline-block;
}

.popover-trigger {
    cursor: pointer;
}

.popover-content {
    position: absolute;
    z-index: 1;
    display: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 8px;
    // max-width: 240px;
    /* Set a maximum width for the popover */
}

.popover-container .popover-content {
    display: block;
}

/* Adjust popover position based on available space */
.popover-content.left {
    right: 80%;
}

.popover-content.right {
    left: 80%;
}

/* Adjust popover position based on available space */
.popover-content.top {
    bottom: 80%;
}

.popover-content.bottom {
    top: 80%;
}