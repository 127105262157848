.tables {
  &__tabOptions {
    margin-bottom: 14px;
  }

  &__tab {
    &--vdpByChannel {
      table thead tr th:first-child {
        text-align: left;
      }
    }

    &--vdpROI {
      padding-top: 18px;
    }
  }
}
