@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.resultsSummary {
  display: grid;
  row-gap: 16px;
  column-gap: 20px;
  grid-template-columns: repeat(3, minmax(auto, 429px));
  grid-template-rows: repeat(2, minmax(auto, max-content));
  grid-template-areas:
    "top-section top-section top-section"
    "left-section left-section right-section";
  &__topSection {
    grid-area: top-section;
    display: grid;
    row-gap: 16px;
    max-width: calc(100vw - 40px);
  }

  &__leftSection {
    grid-area: left-section;
    display: grid;
    row-gap: 21px;
    max-width: calc(100vw - 40px);
  }

  &__rightSection {
    grid-template-rows: repeat(3, minmax(auto, max-content));
    grid-area: right-section;
    display: grid;
    row-gap: 21px;
    max-width: calc(100vw - 40px);
  }

  @include breakpoint-to(lg) {
    row-gap: 13px;
    column-gap: 15px;
    grid-template-areas:
      "top-section top-section"
      "left-section left-section"
      "right-section right-section";
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);

    &__topSection {
      row-gap: 13px;
    }

    &__leftSection {
      row-gap: 13px;
    }

    &__rightSection {
      row-gap: 13px;
    }
  }

  @include breakpoint-to(md) {
    row-gap: 13px;
    column-gap: 15px;
    grid-template-areas:
      "top-section"
      "left-section"
      "right-section";
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
  }
}

.metricsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;

  @include breakpoint-to(lg) {
    row-gap: 13px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @include breakpoint-to(md) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.reportsContainer {
  display: flex;
  flex-direction: column;
  gap: 9px;
  max-width: calc(100vw - 40px);

  .barChart {
    height: 500px;
  }

  @include breakpoint-to(lg) {
    .barChart {
      height: 373px;
      width: 100%;
    }
  }

  @include breakpoint-to(md) {
    .barChart {
      height: 292.24px;
      width: 100%;
    }
  }
}

.resultScoresLegend {
  max-width: calc(100vw - 40px);
  &__title {
    margin-bottom: 10px;
  }

  &__description {
    margin-bottom: 21px;
    line-height: 21px;
  }

  &__items {
    border: 1px solid map-get($gray, 300);
    border-radius: 4px;
    padding: 15px 11px;
    display: flex;
    gap: 19px;

    &__legend {
      flex: 1;

      &__label {
        text-transform: capitalize;
        margin-bottom: 6px;

        &--green {
          color: map-get($greenLight, 600);
        }

        &--yellow {
          color: map-get($yellow, 500);
        }

        &--red {
          color: map-get($error, 600);
        }
      }

      &__info {
        line-height: 21px;
      }
    }

    &__divider {
      border: 0.5px solid map-get($gray, 300);
      // height: 120px;
    }
  }

  @include breakpoint-to(md) {
    &__items {
      flex-direction: column;
      &__divider {
        height: 0px;
        width: 100%;
      }
    }
  }
}

// .attentionContainer {
// }

.topVDP {
  $border: 1px solid map-get($gray, 300);
  $border-error: 2px solid map-get($error, 500);

  &__table {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding: 10px 14px 10px 10px;
    border: $border;
    border-radius: 4px;
    &__title {
      border-bottom: $border;
      width: 100%;
      padding: 4px 0px 12px 0px;
      &--text {
        color: $black;
      }
    }
    &__select {
      margin-top: 10px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      border-bottom: $border;
      width: 100%;
      padding: 8px 0px 8px 0px;
      &--text {
        color: map-get($gray, 600);
      }
    }
    &__more {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      &__button {
        width: fit-content;
        padding: 12px 0px 4px 0px !important;
        &--text {
          color: map-get($primary, 700);
        }
      }
    }
    &__noData {
      margin-top: 10px;
      text-align: center;
    }
  }
}

.salesFrontContainer {
  border: 1px solid map-get($gray, 300);
  border-radius: 4px;
  padding: 17px 25px;
  max-width: calc(100vw - 92px);

  &__quickFilter {
    margin-bottom: 20px;
  }

  &__label {
    margin-bottom: 20px;
  }

  .frontLineReadyTable {
    tr th {
      text-align: left;
    }
  }

  .withLink {
    color: map-get($blue, 600);
    font-size: map-get($textSizes, sm);
    font-weight: 600 !important;
    transition: color ease-in-out 320ms;

    &:hover {
      color: map-get($blue, 800);
    }
  }
}

.topVehicle {
  align-self: baseline;
  border: 1px solid map-get($gray, 300);
  border-radius: 4px;
  padding: 16px 14px;

  &__label {
    margin-bottom: 15px;
  }
}
