@import "../../scss/breakpoints.scss";

.frontLineReadyVehicleTrend {
  margin-bottom: 16px;
  max-width: 100vw;

  @include breakpoint-to(sm) {
    grid-column: 1;
    justify-content: end;
  }

  &__toggleWidget {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-left: auto;
  }

  &__widgetLabel {
    display: flex;

    @include breakpoint-to(lg) {
      grid-column: 1/3;
    }

    @include breakpoint-to(sm) {
      grid-column: unset;
    }
  }

  &__top {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__graph {
    width: initial;
    display: grid;
    gap: 21px;
    max-width: 100%;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 1fr) 0px;

    @include breakpoint-to(lg) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: minmax(0, 1fr);
    }

    &--showWidgets {
      grid-template-columns: minmax(0, 1fr) 250px;
    }

    &__line {
      flex: 2;
    }

    &__widgets {
      display: grid;
      gap: 20px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;

      @include breakpoint-to(lg) {
        grid-template-rows: 24px repeat(3, 0);
        gap: 0;
      }

      @include breakpoint-to(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        column-gap: 0;
      }

      &--showWidgets {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
        column-gap: 17px;

        @include breakpoint-to(sm) {
          column-gap: 0px;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(6, auto);
        }
      }

      &--salesGoal {
        background-color: #68bad6;
      }

      &--salesPace {
        background-color: #90cdb7;
      }

      &--frontLineReady {
        background-color: #e9c478;
      }

      &--salesFlrRatio {
        background-color: #b3d3d8;
      }

      &--flrToHitGoal {
        background-color: #eee0cc;
      }
    }
  }
}

.reporting {
  width: 100%;
}

.reporting__tableLabel {
  margin-bottom: 18px;
}

.reporting__tables {
  &__table {
    margin-top: 16px;

    &__ranks {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    table {
      table-layout: fixed !important;
    }

    table tbody td {
      text-align: center;
    }
  }
}
