@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";

.openModal {
  &,
  .background {
    display: block !important;
    opacity: 1 !important;
    inset: 0 !important;
    z-index: 10 !important;
  }
}

.background {
  display: none;
  opacity: 0;
  inset: -100vh;
  position: absolute;
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 800ms;
  z-index: -1;
}

.container {
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translate(-50%, -30%);
  background: $white;
  border-radius: 12px;
  box-shadow: 0px 100px 200px 0px rgba(52, 64, 84, 0.18);
  max-width: 446px;
  width: 100%;

  @include breakpoint-to(sm) {
    width: 90%;
  }
}

.header {
  padding: 24px 24px 18px;
  display: flex;
  justify-content: space-between;
  align-items: ce;
  border-bottom: 1px map-get($gray, 200) solid;

  .closeButton {
    padding: 0;
  }
}

.body {
  padding: 20px 24px 18px;
}
