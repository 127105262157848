@import "../../../../scss/colors.scss";

.resultsScoreBadge {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin: auto;

  &--red {
    background-color: map-get($error, 600);
  }

  &--yellow {
    background-color: map-get($yellow, 500);
  }

  &--green {
    background-color: map-get($greenLight, 500);
  }
}
