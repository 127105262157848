@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.badge {
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 16px;
  font-weight: 500;

  &:is(.hasIcon) {
    svg {
      height: 1em;
      color: inherit;
      width: 1em;

      * {
        stroke: currentColor;
      }
    }
  }

  &.sm {
    gap: 4px;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;

    &:is(.hasIcon) {
      padding-block: 2px;
      padding-inline: 6px 8px;
    }
  }

  &.md {
    gap: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 10px;

    &:is(.hasIcon) {
      padding-block: 2px;
      padding-inline: 8px 10px;
    }
  }

  &.lg {
    gap: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 12px;

    &:is(.hasIcon) {
      padding-block: 4px;
      padding-inline: 10px 12px;
    }
  }

  &.blueLight {
    background-color: map-get($blueLight, 50);
    color: map-get($blueLight, 700);
  }

  &.primary {
    background-color: map-get($primary, 50);
    color: map-get($primary, 700);
  }

  &.error {
    background-color: map-get($error, 50);
    color: map-get($error, 700);
  }

  &.gray {
    background-color: map-get($gray, 50);
    color: map-get($gray, 700);
  }

  &.indigo {
    background-color: map-get($indigo, 50);
    color: map-get($indigo, 700);
  }

  &.success {
    background-color: map-get($success, 50);
    color: map-get($success, 700);
  }

  &.warning {
    background-color: map-get($warning, 50);
    color: map-get($warning, 700);
  }
}
