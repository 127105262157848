.lineChart {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__dateRange {
    margin-bottom: 18px;
  }

  &__chart {
    height: 458px;
    box-sizing: border-box;
  }
}
