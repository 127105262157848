@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";

.userManagementContainer {
  background-color: $black;
  position: relative;
  padding-top: 10.7vw;
  padding-inline: 8px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-x: auto;
  z-index: 1;

  @include breakpoint-to(lg) {
    padding-top: 10vh;
  }

  @include breakpoint-to(sm) {
    padding-top: 6vh;
  }
}
