@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

$border: 1px solid map-get($gray, 300);

.buttonGroup {
  display: flex;
  border: $border;
  border-radius: 4px;
  width: fit-content;
  overflow: hidden;

  &__button {
    color: map-get($gray, 700);
    font-size: map-get($textSizes, sm);
    font-weight: 600;
    display: block;
    background-color: $white;
    box-shadow: none;
    border: none;
    padding: 10px 16px;
    border-right: $border;
    transition: all ease-in-out 400ms;

    &:last-child {
      border-right: none;
    }

    &:hover {
      cursor: pointer;
    }

    &--active,
    &:hover {
      background-color: map-get($gray, 700);
      color: $white;
    }

    @include breakpoint-to(sm) {
      padding: 8px 10px;
      font-size: map-get($textSizes, xs);
    }
  }
}
