@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.metricCard {
  padding: 14px 24px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  min-width: 205px;
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-to(lg) {
    padding: 10px 16px;
  }

  &__label_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-size: map-get($textSizes, sm);
    font-weight: 500;
    margin-top: 8px;
  }

  &__value {
    font-size: map-get($displaySizes, md);
    font-weight: 600;

    @include breakpoint-to(lg) {
      font-size: map-get($displaySizes, sm);
    }
  }

  button {
    background: none;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}

.metricCard__input {
  outline: none;
  background: transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 0.5px solid #000;
  font-size: map-get($displaySizes, md);
  font-weight: 600;
  width: 100%;
}

.editModal {
  &__label {
    color: map-get($gray, 900);
  }

  &__submitButton {
    margin-top: 22px;
    width: 100%;
  }
}
