// Colors
$white: #ffffff;
$black: #000000;

$gray: (
  25: #fcfcfd,
  50: #f9fafb,
  100: #f2f4f7,
  200: #eaecf0,
  300: #d0d5dd,
  400: #98a2b3,
  500: #667085,
  600: #475467,
  700: #344054,
  800: #1d2939,
  900: #101828,
);

$primary: (
  25: #fcfcfd,
  50: #f8f9fc,
  100: #eaecf5,
  200: #d5d9eb,
  300: #b3b8db,
  400: #717bbc,
  500: #4e5ba6,
  600: #3e4784,
  700: #363f72,
  800: #293056,
  900: #101828,
);

$error: (
  25: #fffbfa,
  50: #fef3f2,
  100: #fee4e2,
  200: #fecdca,
  300: #fda39b,
  400: #f97066,
  500: #f04438,
  600: #d92d20,
  700: #b42318,
  800: #912018,
  900: #7a271a,
);

$warning: (
  25: #fffcf5,
  50: #fffaeb,
  100: #fef0c7,
  200: #fedf89,
  300: #fec84b,
  400: #fdb022,
  500: #f79009,
  600: #dc6803,
  700: #b54708,
  800: #93370d,
  900: #7a2e0e,
);

$yellow: (
  25: #fefdf0,
  50: #fefbe8,
  100: #fef7c3,
  200: #feee95,
  300: #fde272,
  400: #fac515,
  500: #eaaa08,
  600: #ca8504,
  700: #a15c07,
  800: #854a0e,
  900: #713b12,
);

$moss: (
  25: #fafdf7,
  50: #f5fbee,
  100: #e6f4d7,
  200: #ceeab0,
  300: #acdc79,
  400: #86cb3c,
  500: #669f2a,
  600: #4f7a21,
  700: #3f621a,
  800: #335015,
  900: #2b4212,
);

$greenLight: (
  25: #fafef5,
  50: #f3fee7,
  100: #e4fbcc,
  200: #d0f8ab,
  300: #a6ef67,
  400: #85e13a,
  500: #66c61c,
  600: #4ca30d,
  700: #3b7c0f,
  800: #326212,
  900: #2b5314,
);

$success: (
  25: #f6fef9,
  50: #ecfdf3,
  100: #d1fadf,
  200: #a6f4c5,
  300: #6ce9a6,
  400: #32d583,
  500: #12b76a,
  600: #039855,
  700: #027a48,
  800: #05603a,
  900: #054f31,
);

$blue: (
  25: #f5faff,
  50: #eff8ff,
  100: #d1e9ff,
  200: #b2ddff,
  300: #84caff,
  400: #53b1fd,
  500: #2e90fa,
  600: #1570ef,
  700: #175cd3,
  800: #1849a9,
  900: #194185,
);

$blueLight: (
  25: #f5fbff,
  50: #f0f9ff,
  100: #e0f2fe,
  200: #b9e6fe,
  300: #7cd4fd,
  400: #36bffa,
  500: #0ba5ec,
  600: #0086c9,
  700: #026aa2,
  800: #065986,
  900: #0b4a6f,
);

$indigo: (
  25: #f5f8ff,
  50: #eef4ff,
  100: #e0eaff,
  200: #c7d7fe,
  300: #a4bcfd,
  400: #8098f9,
  500: #6172f3,
  600: #444ce7,
  700: #3538cd,
  800: #2d31a6,
  900: #2d3282,
);

$orange: (
  25: #fefaf5,
  50: #fef6ee,
  100: #fdead7,
  200: #f9dbaf,
  300: #f7b27a,
  400: #f38744,
  500: #ef6820,
  600: #e04f16,
  700: #b93815,
  800: #932f19,
  900: #772917,
);

$orangeDark: (
  25: #fff9f5,
  50: #fff4ed,
  100: #ffe6d5,
  200: #ffd6ae,
  300: #ff9c66,
  400: #ff692e,
  500: #ff4405,
  600: #e62e05,
  700: #bc1b06,
  800: #97180c,
  900: #771a0d,
);
