@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  border-radius: 4px;
  transition: border ease-in-out 400ms, background ease-in-out 400ms,
    box-shadow ease-in-out 450ms;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    user-select: none;
  }

  &.primary {
    color: $white;
    border: 1px solid map-get($primary, 600);
    background: linear-gradient(
      45deg,
      map-get($gray, 700) 0%,
      map-get($gray, 600) 100%
    );

    &:disabled {
      background: map-get($primary, 200) !important;
      border: 1px solid map-get($primary, 200);
    }

    &:hover:not(:disabled) {
      font-weight: 600;
      background: linear-gradient(
        45deg,
        map-get($gray, 800) 0%,
        map-get($gray, 600) 100%
      );
    }
  }

  &.secondary {
    color: map-get($gray, 700);
    background: $white;
    border: 1px solid map-get($primary, 300);

    &:disabled {
      background: map-get($primary, 100) !important;
      border: 1px solid map-get($primary, 100);
    }
    &:hover:not(:disabled) {
      font-weight: 600;
      background: map-get($primary, 200);
      border: 1px solid map-get($primary, 200);
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px map-get($gray, 100);
    }
  }

  &.outline {
    color: map-get($gray, 700);
    background-color: $white;
    border: 1px solid map-get($gray, 300);

    &:disabled {
      border: 1px solid map-get($gray, 200);
      color: map-get($gray, 300) !important;
    }
    &:hover:not(:disabled) {
      font-weight: 600;
      color: map-get($gray, 800);
      background: map-get($gray, 50);
    }
  }

  &.text {
    color: map-get($gray, 600);
    border: none;
    background-color: unset;
    box-shadow: none;

    &:disabled {
      color: map-get($gray, 300);
    }
    &:hover:not(:disabled) {
      font-weight: 600;
      color: map-get($gray, 700);
      background-color: map-get($gray, 50);
    }
  }

  &.error {
    color: $white;
    background: map-get($error, 600);
    border: 1px solid map-get($error, 600);

    &:disabled {
      background: map-get($error, 200);
      border: 1px solid map-get($error, 200);
    }
    &:hover:not(:disabled) {
      background: map-get($error, 700);
      border: 1px solid map-get($error, 700);
    }
  }

  &.sm {
    padding: 8px 14px;
    font-size: map-get($textSizes, sm);
  }

  &.md {
    padding: 10px 16px;
    font-size: map-get($textSizes, sm);
  }

  &.lg {
    padding: 10px 18px;
    font-size: map-get($textSizes, md);
  }

  &.xl {
    padding: 12px 20px;
    font-size: map-get($textSizes, md);
  }

  &.xxl {
    padding: 16px 28px;
    font-size: map-get($textSizes, lg);
  }
}
