@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";

.tabs {
  background-color: map-get($gray, 50);
  border: 1px solid map-get($gray, 100);
  display: flex;
  gap: 8px;
  padding: 6px;

  button {
    width: fit-content;
  }
}
.tabDesktop {
  display: flex;
  @include breakpoint-to(sm) {
    display: none;
  }
}
.tabPhone {
  display: none;
  @include breakpoint-to(sm) {
    display: flex;
  }

  &__list {
    z-index: 5 !important;
  }
}
