.dashboard {
  width: calc(100% - 40px);
  height: 100%;
  min-height: calc(100vh - 40px);
  position: relative;
}

.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
.dragContainer {
  padding: 20px;
  top: 0;
  left: 0;
  position: absolute;
  height: inherit;
  min-height: calc(100vh - 40px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  overflow: hidden;
}
.dropBox {
  width: 100px;
  height: 100px;
  border: 1px solid black;
}
