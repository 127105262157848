@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.background {
  background: url("../../../public/assets/images/login-bg.jpg");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  inset: 0;
  z-index: -1;

  @include breakpoint-to(lg) {
    background-position-y: 120px;
  }
}

.login {
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  display: flex;
  flex-direction: row;
  margin-inline: auto;

  &__banner {
    box-sizing: border-box;
    background: linear-gradient(
      26.57deg,
      map-get($gray, 800) 8.33%,
      map-get($gray, 700) 91.67%
    );
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    min-width: 417px;
    padding: 20px 28.5px;

    img {
      width: 169px;
      height: auto;
      margin-bottom: 20px;
    }

    h1 {
      font-size: map-get($displaySizes, xs);
      font-weight: 700;
      margin: 0;
    }

    p {
      font-size: map-get($textSizes, md);
      margin: 0;
    }
  }

  &__form {
    box-sizing: border-box;
    background: $white;
    padding: 39px 52px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 417px;

    &__error {
      color: map-get($error, 500);
      font-size: 14px;
      margin-top: 5px;
    }

    &__togglePassword {
      cursor: pointer;
      &--hide {
        opacity: 0.5;
      }
    }

    &__actions {
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      a {
        color: map-get($primary, 500);
        text-decoration: none;
        font-weight: 600;
      }
    }

    &__captcha {
      &__error {
        color: map-get($error, 500);
      }
    }
  }

  @include breakpoint-to(lg) {
    flex-direction: column;
    box-sizing: border-box;
    max-width: 446px;
    width: 100%;

    &__banner {
      min-width: unset;
      width: 100%;
      padding: 34.5px 10.5px;

      img {
        width: 120.71px;
        margin-bottom: 15px;
      }

      h1 {
        font-size: map-get($textSizes, xl);
      }

      p {
        font-size: map-get($textSizes, sm);
      }
    }

    &__form {
      min-width: unset;
      width: 100%;
      padding: 20.5px 22.5px;
    }
  }
}
