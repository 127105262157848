// Noted: Keep in mind that we do desktop first scss design
// Notes: If these values changed please align with "frontend/src/types/EBreakpoints.ts"
$breakpoints: (
  /** 
  *** sm: This breakpoint value (639px) would correspond to small mobile devices, 
  *** such as older iPhones or Android phones with smaller screens. 
  **/
    "sm": 639px,
  /** 
  *** md: This breakpoint value (767px) would correspond to larger mobile devices, 
  *** such as newer iPhones or Android phones with larger screens, or small tablets.
  **/
    "md": 767px,
  /** 
  *** lg: This breakpoint value (1023px) would correspond to medium-sized tablets, 
  *** such as iPads or Android tablets, or smaller laptop screens.
  **/
    "lg": 1023px,
  /** 
  *** xl: This breakpoint value (1279px) would correspond to large-sized monitors, 
  *** such as larger laptops and desktop monitors.
  **/
    "xl": 1279px,
  /** 
  *** 2xl: This breakpoint value (1535px) would correspond to very large-sized monitors, 
  *** such as ultra-wide or 4K displays.
  **/
    "2xl": 1535px
);

@mixin breakpoint-to($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @media (max-width: $size) {
    @content;
  }
}
