@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";

$border: 1px solid map-get($gray, 300);

.barGraphContainer {
  background: map-get($gray, 50);
  border: $border;
  border-radius: 4px;
  box-sizing: border-box;
  /* *
  /* make sure this is in px and two values max only for 
  /* able to compute the height and width of the chart 
  **/
  padding: 17px 21px;
  height: 100%;
  width: 100%;

  @include breakpoint-to(lg) {
    padding: 17px 15px;
  }

  @include breakpoint-to(md) {
    padding: 17px 8px;
  }
}

.barGraphTopSection {
  display: flex;
}

.filterSeriesContainer {
  margin-left: auto;
  position: relative;

  .filterSeriesModal {
    position: absolute;
    margin-top: 5px;
    margin-left: -177px;
    padding: 10px;
    width: 260px;
    display: flex;
    background-color: $white;
    border: 1px solid map-get($gray, 200);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1;

    .filterTitle {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    .filterSeriesDivider {
      border: 1px solid map-get($gray, 200);
      margin: 0;
    }

    .seriesContainer {
      display: flex;
      gap: 8px;
      flex-direction: column-reverse;

      .series {
        border-radius: 4px;
        border: unset;
        padding: 10px 15px;
        text-align: center;
        font-size: 14px;
        color: $white;

        &:hover,
        &.seriesSelected {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
}

.barGraphContent {
  width: 100%;
  height: 88.7%; // Relative to the parent's height container so that the chart wouldn't overlap
  box-sizing: border-box;

  &__chart {
    display: flex;
    justify-content: center;
  }
}
