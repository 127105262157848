@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.dateRange {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;

  @include breakpoint-to(lg) {
    gap: 0;
  }
}

.dateRange__leftSection {
  display: flex;
  flex-direction: column;
  @include breakpoint-to(lg) {
    width: 100%;
  }

  &__label {
    margin-bottom: 5px;
  }

  &__options {
    display: flex;
    gap: 10px;

    &__quickRange {
      @include breakpoint-to(lg) {
        width: 100%;

        button {
          flex: 1;
        }
      }
    }

    &__customRange {
      width: auto;
    }

    // :global ignores scss module to transform into a unique className
    :global .react-datepicker-popper {
      z-index: 5;
    }

    @include breakpoint-to(lg) {
      flex-wrap: wrap;
      gap: 12px;
      flex-direction: column;
      text-align: left;
      width: 100%;
    }
  }
}

.dateRange__labelSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: end;

  @include breakpoint-to(lg) {
    align-self: flex-start;
    flex-direction: row;
    gap: 10px;
  }
}
