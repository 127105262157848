@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

$border: 1px solid map-get($gray, 300);
$border-error: 2px solid map-get($error, 500);

.attentionList {
  // min-width: 320px;
  // max-width: 100%;
  width: 100%;
  padding: 10px 14px 10px 10px;
  border: $border-error;
  border-radius: 4px;
  &__title {
    align-items: center;
    display: flex;
    gap: 10px;
    color: map-get($error, 600);
    border-bottom: $border;
    width: 100%;
    padding: 4px 0px 12px 0px;
    &__icon {
      font-size: map-get($textSizes, xl);
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border;
    width: 100%;
    padding: 4px 0;
    &__text {
      flex: 1;
      color: map-get($gray, 600);
    }
  }
}
