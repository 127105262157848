@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";

$column-box-shadow: 6px 1px 16px -3px rgba(50, 43, 47, 0.15);

.frontLineReadyVehicle {
  margin-bottom: 22px;
  min-width: 0;

  @include breakpoint-to(sm) {
    grid-column: 1;
    justify-content: end;
  }

  &__toggleWidget {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-left: auto;
  }

  &__widgetLabel {
    display: flex;

    @include breakpoint-to(lg) {
      grid-column: 1/3;
    }

    @include breakpoint-to(sm) {
      grid-column: unset;
    }
  }

  &__top {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__graph {
    width: initial;
    display: grid;
    gap: 21px;
    max-width: 100%;
    box-sizing: border-box;
    grid-template-columns: minmax(0, 1fr) 0px;

    @include breakpoint-to(lg) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: minmax(0, 1fr);
    }

    &--showWidgets {
      grid-template-columns: minmax(0, 1fr) 250px;
    }

    &__line {
      flex: 2;
    }

    &__widgets {
      display: grid;
      gap: 20px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;

      @include breakpoint-to(lg) {
        grid-template-rows: 24px repeat(3, 0);
        gap: 0;
      }

      @include breakpoint-to(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        column-gap: 0;
      }

      &--showWidgets {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
        column-gap: 17px;

        @include breakpoint-to(sm) {
          column-gap: 0px;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(6, auto);
        }
      }

      &--salesGoal {
        background-color: #68bad6;
      }

      &--salesPace {
        background-color: #90cdb7;
      }

      &--frontLineReady {
        background-color: #e9c478;
      }

      &--salesFlrRatio {
        background-color: #b3d3d8;
      }

      &--flrToHitGoal {
        background-color: #eee0cc;
      }
    }
  }
}

.tables {
  &__tabOptions {
    margin-bottom: 14px;
  }

  &__tab {
    &--dealRatingsTable {
      text-align: left;
    }

    &--tipReportTable table {
      table-layout: fixed !important;

      .vehicleHeader {
        width: 200px !important;
      }

      .stockNumberHeader {
        width: 75px !important;
      }

      .dealRatingHeader,
      .newDealRatingHeader,
      .nextDealRatingHeader {
        width: 97px !important;
      }

      .priceHeader,
      .newPriceHeader {
        width: 53px !important;
      }

      .priceChangeHeader {
        width: 100px !important;
      }

      .priceNextDealRatingHeader {
        width: 97px !important;
        padding: 0px 15px;
      }

      .priceChangeDealRatingHeader {
        width: 150px !important;
        padding: 0px 15px;
      }

      .ageHeader {
        width: 25px !important;
      }
    }

    &--tipReportTable table {
      table-layout: fixed;
    }

    &--channelStatsTable table {
      width: initial;

      // Autotrader header color
      .autotraderHeader {
        background-color: map-get($primary, 100);
      }

      // cargurus header color
      .cargurusHeader {
        background-color: map-get($warning, 100);
      }

      // cars.com header color
      .carsDotComHeader {
        background-color: map-get($orange, 100);
      }

      // carfax header color
      .carfaxHeader {
        background-color: map-get($moss, 200);
      }

      // website header color
      .websiteHeader {
        background-color: map-get($indigo, 200);
      }

      // Overlays the td rows of sticky columns
      // thead {
      //   position: sticky;
      //   z-index: 4;
      // }

      // Sticky column for Vehicle
      // thead tr:nth-child(2) th:nth-child(2),
      // tbody tr td:nth-child(2) {
      //   position: sticky;
      //   left: 0;
      //   z-index: 1;
      // }

      // Box shadow for Vehicle Column
      tbody tr td:nth-child(2) {
        background-color: $white;
        box-shadow: $column-box-shadow;
      }

      .vehicleHeader {
        min-width: 254px;
        display: block;
      }

      .stockHeader {
        display: block;
        width: 67.81px !important;
      }

      .description {
        &Header {
          min-width: 375px;
          display: block;
        }

        &Cell {
          button {
            display: contents;
            margin-left: 5px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .priceHeader {
        text-align: left;
        min-width: 78px;
      }

      .dealRatingHeader {
        text-align: left;
        min-width: 75px;
      }

      .srpHeader,
      .vdpHeader {
        text-align: left;
        min-width: 67px;
      }

      .srpVdpRatioHeader {
        text-align: left;
        min-width: 119px;
      }
    }

    &--preShootReportTable table thead tr th {
      text-align: left;
    }
  }
}
