@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.textInput {
  > .label {
    font-size: map-get($textSizes, sm);
    font-weight: 500;
    margin-bottom: 6px;
  }
  > .input {
    position: relative;
    .iconPrefix {
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
    .suffixIcons {
      align-items: center;
      display: flex;
      gap: 4px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;

      .iconSuffix,
      .errorIcon {
        color: map-get($error, 500);
        font-size: map-get($textSizes, md);
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .inputBox {
      width: 100%;
      // width: calc(100% - 28px);
      padding: 10px 14px;
      gap: 8px;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 4px;
      color: map-get($gray, 500);
    }
    > .error {
      border: 1px solid map-get($error, 300);
    }
    .inputIcon {
      width: 100%;
      // width: calc(100% - 52px);
      padding-left: 38px;
    }
  }
  > .error {
    color: map-get($error, 500);
    font-size: map-get($textSizes, sm);
    margin-top: 5px;
  }
}
