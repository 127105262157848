@import "./scss/colors.scss";
@import "./scss/typography.scss";

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
  display: flex;

}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main {
  min-height: 100vh;
  min-width: 100%;
}

.ka-icon-tree-arrow {
  display: flex;
  align-items: center;
}

.ka-thead-cell-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ka-thead-cell-content > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// react-toastify override styles
.Toastify__toast {
  padding: 16px !important;
  min-height: fit-content !important;

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    align-items: flex-start !important;
    font-size: map-get($textSizes, sm);
  }

  .Toastify__toast-icon {
    flex-shrink: 0;
    font-size: map-get($textSizes, lg);
  }

  &--default {
    color: map-get($gray, 700) !important;
    background-color: map-get($gray, 25) !important;
    border: 1px solid map-get($gray, 300) !important;

    .Toastify__close-button,
    .Toastify__toast-icon {
      color: map-get($gray, 600) !important;
    }
  }

  &--info {
    color: map-get($primary, 700) !important;
    background-color: map-get($primary, 25) !important;
    border: 1px solid map-get($primary, 300) !important;

    .Toastify__close-button,
    .Toastify__toast-icon {
      color: map-get($primary, 600) !important;
    }
  }

  &--success {
    color: map-get($success, 700) !important;
    background-color: map-get($success, 25) !important;
    border: 1px solid map-get($success, 300) !important;

    .Toastify__close-button,
    .Toastify__toast-icon {
      color: map-get($success, 600) !important;
    }
  }

  &--warning {
    color: map-get($warning, 700) !important;
    background-color: map-get($warning, 25) !important;
    border: 1px solid map-get($warning, 300) !important;

    .Toastify__close-button,
    .Toastify__toast-icon {
      color: map-get($warning, 600) !important;
    }
  }

  &--error {
    color: map-get($error, 700) !important;
    background-color: map-get($error, 25) !important;
    border: 1px solid map-get($error, 300) !important;

    .Toastify__close-button,
    .Toastify__toast-icon {
      color: map-get($error, 600) !important;
    }
  }
}

.data-table-header > .chakra-popover__popper {
  z-index: 13 !important;
}