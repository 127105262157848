@import "../../scss/breakpoints.scss";
@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.background {
  background: url("../../../public/assets/images/reset-password-bg.jpg");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  inset: 0;
  z-index: -1;

  @include breakpoint-to(lg) {
    background-position-x: 100%;
  }
}

.forgotPassword {
  background: $white;
  box-shadow: 0px 100px 200px rgba(52, 64, 84, 0.18);
  border-radius: 12px;
  min-width: 446px;

  &__header,
  &__body {
    padding: 24px;
  }

  &__header {
    border-bottom: 1px solid map-get($gray, 200);
    display: flex;
    gap: 16px;

    &__icon {
      border: 1px solid map-get($gray, 200);
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 10px;

      img {
        height: 24px;
        width: auto;
        margin: 15px 16px;
      }
    }

    &__title {
      display: flex;
      flex-flow: column;
      gap: 4px;

      b,
      p {
        margin: 0;
      }

      b {
        font-size: map-get($textSizes, lg);
        font-weight: 600;
        color: map-get($gray, 900);
        margin-bottom: 4px;
      }

      p {
        font-size: map-get($textSizes, sm);
        color: map-get($gray, 600);
      }

      .errorMessage {
        color: map-get($error, 600);
      }
    }

    &__close {
      margin-left: auto;
      transition: opacity ease-in-out 300ms;
      svg {
        color: map-get($gray, 500);
        font-size: map-get($displaySizes, sm);
      }

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  @include breakpoint-to(lg) {
    min-width: unset;
    max-width: 446px;
    width: 100%;
  }
}
