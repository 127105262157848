@import "../../../../../../scss/typography.scss";

.budgetColumn {
  display: flex;
  align-items: center;

  &__editBudget {
    padding: 8px !important;
    font-size: map-get($textSizes, xl) !important;
  }
}
