@import "../.../../../scss/colors.scss";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingContainer {
  position: relative;

  &--isLoading {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.663);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    }
  }

  .content {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    z-index: 5;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &__caption {
      color: map-get($gray, 700);
    }

    &__icon {
      animation: spin 1s linear infinite;
    }
  }
}
