@import "../../scss/colors.scss";
@import "../../scss/typography.scss";

.statisticCard {
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 21.5px;
  padding: 20px 12px;
  border: 1px solid map-get($gray, 200);
  border-radius: 4px;

  &__section {
    &__label {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      color: map-get($gray, 600);

      &--neutral {
        color: map-get($gray, 600);
      }

      &--noValue {
        color: map-get($gray, 300);
      }
    }

    &__description {
      font-size: 10px;
      font-weight: bold;
      // text-transform: uppercase;
      font-style: italic;
    }

    &__values {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__total {
        &--bearish {
          color: map-get($error, 600);
        }

        &--bullish {
          color: map-get($success, 600);
        }

        &--neutral {
          color: map-get($gray, 900);
        }

        &--noValue {
          color: map-get($gray, 300);
        }
      }

      &__growth {
        &--neutral,
        &--noValue {
          color: map-get($gray, 300) !important;
        }
      }
    }
  }

  &__divider {
    height: 100%;
    border: 1px solid map-get($gray, 200);
  }
}
